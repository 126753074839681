import { LearningCourseRepository } from '@/repositories/LearningCourseRepository';

export const useChangeCategoryForm = (projectId: number) => {
  const changeCategory = (learningCourseId: number, learningCategoryId: number) => {
    const repository = new LearningCourseRepository(projectId);
    repository.changeCategory(learningCourseId, learningCategoryId);
  };

  return {
    changeCategory,
  };
};
